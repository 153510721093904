import React from "react";
// react router
import { BrowserRouter as Router, Route, Routes, useLocation  } from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";
// Headers
import Header from "../Main/Header";
import Footer from "../Main/Footer";

// pages
import Home from "./Home";
import About from "./About";
import Summary from "./Summary";
import Error from "./Error";


const AnimatedRoutes = () => {
    const location = useLocation();

    return (
        <TransitionGroup>
            <CSSTransition key={location.key} timeout={500} classNames="fade">
                <Routes location={location}>
                    <Route path="/" element={<Home />} />
                    <Route path="/summary" element={<Summary />} />
                    <Route path="/about" element={<About />} />
                    <Route path="*" element={<Error />} />
                </Routes>
            </CSSTransition>
        </TransitionGroup>
    );
};

const RouterSetup = () => {
    return (
        <Router>
            <Header />
            <AnimatedRoutes />
            <Footer />
        </Router>
    );
};

export default RouterSetup;