import {
	faFacebookSquare,
	faGithubSquare,
	faInstagramSquare,
	faLinkedin,
} from "@fortawesome/free-brands-svg-icons";

export const socials = [
	{
		id: 1,
		url: "https://github.com/MoudyAmmar",
		icon: faGithubSquare,
	},
	{
		id: 2,
		url: "https://www.linkedin.com/in/mahmoud-ammar-2449532ba/",
		icon: faLinkedin,
	}
];
