import React from "react";
import mainPortrait from "../assets/images/main-portrait.jpg";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGithubSquare, faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { firstName, lastName } from "../assets/data/constant";
import { socials } from "../assets/data/socials";
const Home = () => {
	return (
		<div className="page">
			<div id="main-container" className="container">
				<h1>
					{firstName} <span className="secondary-color">{lastName}</span>
				</h1>
				<img
					src={mainPortrait}
					alt="Main Portrait"
					className="responsive profile-pic"
				/>
				<div className="main-icons">
					<a
						href={socials[0].url}
						target="_blank"
						rel="noopener noreferrer"
					>
						<FontAwesomeIcon icon={faGithubSquare} />
					</a>{" "}
					<a
						href={socials[1].url}
						target="_blank"
						rel="noopener noreferrer"
					>
						<FontAwesomeIcon icon={faLinkedin} />
					</a>
				</div>
				<h1>
					Mech.Eng <span className="secondary-color">Graduate</span>
				</h1>
				<div className="button-container">
					<Link to="/about" className="btn btn-2">
						Start Here
					</Link>
				</div>
			</div>
		</div>
	);
};

export default Home;
