export const certs = [
	{
		id: 0,
		img: "https://vectorlogoseek.com/wp-content/uploads/2019/06/government-of-canada-vector-logo.png",
		title: "Project Management in a Government Context",
		status: "Awarded",
		date: "May 2024 ",
		url: "https://catalogue.csps-efpc.gc.ca/product?catalog=TRN323&cm_locale=en",
	},
	{
		id: 1,
		img: "https://images.credly.com/size/340x340/images/78f9e4d3-2fd0-4474-8967-317f3575510f/image.png",
		title: "Certified SolidWorks Associate (CSWA)",
		status: "Awarded",
		date: "July 2022",
		url: "https://www.solidworks.com/certifications/mechanical-design-cswa-mechanical-design",
	}
];
