export const about = {
	opener:
		"My name is Mahmoud (I also go by Moudy).",
	summary:
		"I am an experienced Mechanical Engineer graduate from the University of Alberta seeking a Technical E.I.T role. I have a proven ability in managing cross-functional teams, ensuring on-time delivery, quality assurance, effective collaboration, and risk mitigation. I am experienced in engineering management, design and technical development, with a creative approach to solving complex problems.",
	description:
		"With a strong background in mechanical engineering, project management, product design, reliability engineering, robotics and design, I have contributed to academic and professional settings working on multiple large-scale, multi-disciplinary projects. I am passionate about leveraging my expertise in Mechanical Engineering and management skills to ensure the success and scalability of mission-critical project and products.",
	highlights:
		"Progect Management, Mechanical Design, Database Management, Mechatronics, and Product Management.",
	interests:
		"Web Developement, Engineering Sales, Quality Management, Mechanical design, Artifical Intellegence, Blockchain, Machine Learning, Robotics, and Business Administration!",
	hobbies:
		"Reading, Robotics, Soccer, Poetry, and History.",
	volunteer:
		"I currently volunteer as a peer support counselor and mentor for students in STEM.",
	fun: "I speak 3 languages and have lived in 3 different continents.",
};
