import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faBookOpen,
	faEnvelope,
	faUser,
} from "@fortawesome/free-solid-svg-icons";
import { firstName, lastName } from "../assets/data/constant";

const Navbar = () => {
	return (
		<div>
			<ul id="nav" className="nav nav-bg-light">
				<li className="nav-item slam-left">
					<Link to="/">
						<strong>
							{firstName} <span className="secondary-color">{lastName}</span>
						</strong>
					</Link>
				</li>
				<li className="nav-item">
					<Link to="/about">
						About{" "}
						<span className="secondary-color">
							<FontAwesomeIcon icon={faUser} />
						</span>
					</Link>
				</li>
				<li className="nav-item">
					<Link to="/summary">
						Summary{" "}
						<span className="secondary-color">
							<FontAwesomeIcon icon={faBookOpen} />
						</span>
					</Link>
				</li>
				<li className="nav-item">
					<a
						className="contact"
						href="https://mail.google.com/mail/?view=cm&fs=1&to=mfammar@ualberta.ca"
						target="_blank"
						rel="noopener noreferrer"
					>
						Contact{" "}
						<span className="secondary-color">
							<FontAwesomeIcon icon={faEnvelope} />
						</span>
					</a>
				</li>
			</ul>
		</div>
	);
};

export default Navbar;
