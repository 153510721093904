import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { skills } from "../assets/data/skills";
import { certs } from "../assets/data/certs";
import { jobs } from "../assets/data/jobs";

const { Component } = React;

const Resume = () => {
	return (
		<div className="button-container">
			<a
				className="btn btn-3"
				href={
					process.env.PUBLIC_URL +
					"/Resumes/double-template/resume_mahmoud_ammar.pdf"
				}
				target="_blank"
				rel="noopener noreferrer"
			>
				{" "}
				View Resume
			</a>
		</div>
	);
};

const TableOfContents = () => {
	const [scroll, setScroll] = useState("#summary-header");
	useEffect(() => {
		setScroll(scroll);
	}, [scroll]);
	// React useEffect hook that will fire up
	// when "darkTheme" changes
	useEffect(() => {
		const anchor = document.querySelector(scroll);
		anchor.scrollIntoView({ behavior: "smooth", block: "start" });
	}, [scroll]);
	return (
		<Card>
			<ul className="table-contents">
				<li>
					<a onClick={() => setScroll("#Skills")}>Technical Skills</a>
				</li>
				<li>
					<a onClick={() => setScroll("#Certifications")}>Certifications</a>
				</li>

				<li>
					<a onClick={() => setScroll("#Experience")}>Experience</a>
				</li>
			</ul>
		</Card>
	);
};

const Card = ({ children }) => <div>{children}</div>;

const SectionTag = ({ id, text }) => (
	<h2 className="section-tag">
		<span id={id}> {text} </span>
	</h2>
);

const Skill = ({ skill, activeClass, i }) => (
	<li
		className={
			skill.groups.indexOf(activeClass) >= 0 || activeClass === "all"
				? "active"
				: ""
		}
	>
		{skill.skill}
	</li>
);

/*
 * Represents the experience/skills card
 */
class SkillsDisplay extends Component {
	constructor({ skills }) {
		super();
		this.state = {
			active: "all",
			skills: skills,
			groups: this.getGroups(skills),
		};
	}

	getGroups(skills) {
		return skills
			.map((skill) => skill.groups)
			.reduce((a, b) => a.concat(b))
			.filter((value, index, self) => self.indexOf(value) === index);
	}

	changeActive(e) {
		let selection = e.target.value;
		this.setState({ active: selection });
	}

	render() {
		let activeClass = this.state.active;
		return (
			<Card>
				<SectionTag id={"Skills"} text={"I am experienced with"} />
				<ul className="skills-wrap">
					<select onChange={(e) => this.changeActive(e)}>
						<option>all</option>
						{this.state.groups.map((group) => (
							<option>{group}</option>
						))}
					</select>
					{this.state.skills.map((skill, i) => (
						<Skill skill={skill} activeClass={activeClass} key={i} />
					))}
				</ul>
			</Card>
		);
	}
}

const Title = ({ logo, url, alt, date }) => {
	return (
		<div className="job-title">
		<div className="job-company">
			<h2>{alt}</h2>
			{typeof logo === "object" && (
			<a href={url} target="_blank" rel="noopener noreferrer">
				<img src={logo} alt={alt} className="responsive" />
			</a>
			)}
			<div className="dates">{date}</div>
		</div>
		</div>
	);
};

const Description = ({ title, summary, qualifications }) => {
	return (
		<div className="job-description">
			<h3>
				<span className="secondary-color">{title}</span>
			</h3>
			<p>{summary}</p>
			<ul className="job-qualifications">
				{qualifications.map((qualification) => (
					<li>{qualification}</li>
				))}
			</ul>
		</div>
	);
};

const Experience = () => {
	return (
		<Card>
			<SectionTag id="Experience" text={"Professional Experience"} />

			{jobs.map((j, index) => {
				return (
					<div className="flex job">
						<Title
							key={j.id}
							logo={j.logo}
							url={j.url}
							alt={j.alt}
							date={j.date}
						></Title>
						{j.roles.map((m, index) => {
							return (
								<Description
									key={m.id}
									title={m.title}
									summary={m.summary}
									qualifications={m.qualifications}
								></Description>
							);
						})}
					</div>
				);
			})}
		</Card>
	);
};

const Cert = ({ img, title, status, date, url }) => {
	return (
		<a href={url}>
			<article className="cert">
				<img src={img} alt="" />
				<h2>{title}</h2>
				<h3>
					<span className="secondary-color"> {date} </span>
				</h3>
			</article>
		</a>
	);
};

const Certification = () => {
	return (
		<Card>
			<SectionTag id={"Certifications"} text={"Certifications"} />
			<section id="certification" className="certlist">
				{certs.map((c, index) => {
					return <Cert key={c.id} {...c}></Cert>;
				})}
			</section>
		</Card>
	);
};

const Summary = () => {
	return (
		<div className="page">
			<div className="container">
				<h1 id="summary-header">
					Technical <span className="secondary-color">Summary</span>
				</h1>
				<TableOfContents />
				<Resume />
				<SkillsDisplay skills={skills} />
				<Certification />
				<Experience />
			</div>
		</div>
	);
};

export default Summary;
