import cisco from "../images/cisco_logo.png";
import health_canada from "../images/health-canada.png";

export const jobs = [
	{
		id: 0,
		logo: health_canada, // Replace with actual logo if available
		url: "https://www.canada.ca/en/health-canada.html",
		alt: "Health Canada",
		date: "May 2024 - Sept 2024",
		roles: [
			{
				title: "Junior Project Manager",
				summary:
					"Assisted in managing and supporting multiple high-impact projects, including an eight-figure lab upgrade, improving project operations and efficiency in Health Canada's IT infrastructure.",
				qualifications: [
					"Supported an eight-figure lab upgrade, assisting with consultant hiring, change management, and stakeholder communication to meet milestones.",
					"Led lab 10+ member deployment, delivering a project management plan and schedule that improved operational efficiency.",
					"Developed project scope and requirements, enhancing client communication and alignment with needs.",
					"Organized team-building events, boosting cross-departmental collaboration and productivity.",
					"Created a Power BI financial dashboard, providing leadership with real-time project insights for better decision-making.",
					"Supported senior project managers in daily operations, fostering strong communication with contractors and stakeholders to drive project success.",
				],
			},
		],
	},
	{
		id: 1,
		logo: "al-halims-logo", // Replace with actual logo if available
		url: "http://www.alhalimenterprise.com", // URL to Al-Halim Enterprise, replace with actual if available
		alt: "Al-Halim Enterprise",
		date: "Jan 2015 - Oct 2015",
		roles: [
			{
				title: "Project Intern",
				summary:
					"Assisted with planning, procurement, and scheduling tasks, ensuring smooth operations and efficient delivery of various projects.",
				qualifications: [
					"Streamlined project planning, procurement, and scheduling processes, enhancing overall project efficiency and delivery timelines.",
					"Collaborated effectively with cross-functional teams and stakeholders, ensuring alignment with project goals and quality standards.",
					"Maintained comprehensive project documentation, producing clear reports, SOPs, and presentations that facilitated informed decision-making.",
					"Provided critical feedback during project meetings, contributing to continuous improvement and innovation within project teams.",
				],
			},
		],
	},
	{
		id: 2,
		logo: "go-kart-logo", // Replace with actual logo if available
		url: "#", // Replace with actual URL or link if applicable
		alt: "Go-Kart Transmission Project",
		date: "Sept 2022 - Dec 2022",
		roles: [
			{
				title: "Project Manager & Team Lead",
				summary:
					"Led a cross-functional team to design and develop a high-performance Go-Kart transmission, ensuring both safety and performance standards were met.",
				qualifications: [
					"Led a cross-functional team of 6 in the analysis and conceptualization of a Go-Kart transmission, utilizing MATLAB simulations and adhering to client specifications.",
					"Designed a 5-speed sequential gearbox with reverse gear, ensuring shafts and gears met safety and performance standards.",
					"Developed a Quality Control inspection plan to finalize gear and shaft selections.",
					"Monitored performance of bearings and keyway connections, optimizing transmission components for racing.",
					"Ensured efficient time management through Gantt charts and task distribution based on team strengths.",
				],
			},
		],
	},
	{
		id: 3,
		logo: "robot-design-logo", // Replace with actual logo if available
		url: "#", // Replace with actual URL or link if applicable
		alt: "Mechanical Robot Design Project",
		date: "May 2022 - Aug 2022",
		roles: [
			{
				title: "Mechanical Design Engineer",
				summary:
					"Collaborated with a team to design and build a robot to perform specific tasks, ensuring safety, quality control, and project delivery within scope and timeline.",
				qualifications: [
					"Collaborated with a team of 6 to design a robot to perform client-specific tasks.",
					"Utilized SolidWorks to create a model ensuring safety and quality control mechanisms.",
					"Built the robot in the workshop according to the model specifications.",
					"Tracked materials used to maintain budget and timeline compliance.",
					"Reported results in a comprehensive technical report.",
				],
			},
		],
	},
];
