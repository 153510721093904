export const skills = [
	{
		skill: "",
		groups: [
			"Design",
			"Hardware",
			"Programming",
			"Project Management",
			"Tools",
		],
	},
	{
		skill: "SolidWorks",
		groups: ["Design"],
	},
	{
		skill: "AutoCAD",
		groups: ["Design"],
	},
	{
		skill: "Fusion360",
		groups: ["Design"],
	},
	{
		skill: "Git",
		groups: ["Tools", "Programming"],
	},
	{
		skill: "Excel",
		groups: ["Tools"],
	},
	{
		skill: "PowerBI",
		groups: ["Tools", "Project Management"],
	},
	{
		skill: "Jira",
		groups: ["Tools", "Project Management"],
	},
	{
		skill: "Java",
		groups: ["Programming"],
	},
	{
		skill: "Python",
		groups: ["Programming"],
	},
	{
		skill: "Javascript",
		groups: ["Programming"],
	},
	{
		skill: "MATLAB",
		groups: ["Programming"],
	},
	{
		skill: "Arduino",
		groups: ["Hardware"],
	},
	{
		skill: "C02 Laser Cutting",
		groups: ["Hardware"],
	},
	{
		skill: "FDM 3D Printing",
		groups: ["Hardware"],
	},
	{
		skill: "Network Management",
		groups: ["Hardware"],
	},
	{
		skill: "Hardware Assembly",
		groups: ["Hardware"],
	},
	{
		skill: "Scheduling",
		groups: ["Project Management"],
	},
	{
		skill: "Scope Management",
		groups: ["Project Management"],
	},
	{
		skill: "Financial Management",
		groups: ["Project Management"],
	},
	{
		skill: "Risk Assessment",
		groups: ["Project Management"],
	},
	{
		skill: "Supply Chain Management",
		groups: ["Project Management"],
	},
	{
		skill: "Stakeholder Engagement",
		groups: ["Project Management"],
	},
	{
		skill: "Decision Tracking",
		groups: ["Project Management"],
	},
].sort((a, b) => (a.skill > b.skill ? 1 : -1));
